import {
    faCircleUser as fasCircleUser,
    faGear as fasGear,
    faScrewdriverWrench as fasScrewdriverWrench,
    faQuestion as fasQuestion,
    faUsers as fasUsers,
    faUser as fasUser,
}
from '@fortawesome/pro-solid-svg-icons'

import {
    faAngleDown as farAngleDown,
    faAngleUp as farAngleUp,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faGift as farGift,
} from '@fortawesome/pro-regular-svg-icons'

export const solidIcons = [
    fasCircleUser,
    fasGear,
    fasScrewdriverWrench,
    fasQuestion,
    fasUsers,
    fasUser,
]

export const regularIcons = [
    farAngleDown,
    farAngleUp,
    farGift,
    farArrowRightFromBracket,
]

export const lightIcons = [
    // Add light icons here
]